<template>
  <div>
    <a-card title="集 Con 记录">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-range-picker v-model="searchDate" allowClear @change="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.search" placeholder="运单号, con 号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 200px">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">导出</a-button>
          </a-button-group>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            @change="changeTable"
          >
            <template slot="action" slot-scope="value, item">
              <table-action :item="item" @destroy="destroy" />
            </template>
            <template slot="expandedRowRender" slot-scope="record">
              <a-list bordered :dataSource="record.tracking_number_list">
                <a-list-item slot="renderItem" slot-scope="value, index"> {{ index + 1 }} - {{ value }} </a-list-item>
              </a-list>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <a-modal v-model="exportLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导出中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { bindConRecordList, bindConRecordExport } from "@/apis/sorting";
import { removeItem } from "@/utils/functions";
import { exportExcel } from "@/utils/excel";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      searchDate: [undefined, undefined],
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: true,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "con 号",
          dataIndex: "con_number",
          width: 160,
        },
        {
          title: "运单数量",
          dataIndex: "tracking_number_count",
          width: 160,
          customRender: (_value, item) => item.tracking_number_list.length,
        },
        {
          title: "创建人",
          dataIndex: "creator",
          width: 180,
          customRender: (_, item) => item.creator_item?.name,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      exportLoading: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.dataLoading = true;
      this.list();
    },
    list() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      bindConRecordList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    exportExcel() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      this.exportLoading = true;
      bindConRecordExport(searchForm)
        .then((response) => {
          exportExcel(response, "集 Con 记录列表");
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
